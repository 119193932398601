
import "./App.css";
import logo from "./logo.svg";
import "./fonts.css";
import "./Light.css";
import Main from "./Main";
import { Canvas } from "react-three-fiber";
import Particles from "./Particles";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Details from "./Details";
import { useTranslation } from "react-i18next";
import MP3Player from "./MP3Player";
import Carou from "./Carou";
import { useProjects } from "./lib/useProjects";
import { useSpring, animated } from "@react-spring/web";
import { Loader, LoadingOverlay } from "@mantine/core";


const App = () => {
  const [exp, setExp] = useState();
  const [skills, setSkills] = useState();
  const [theme, setTheme] = useState('dark')
  const { t, i18n } = useTranslation();
  const { projects, isLoading, isError } = useProjects();
  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [position, setPosition] = useState([0, 0, 0]);
  const [selectedItem, setSelectedItem] = useState();

  const [activeLang, setActiveLang] = useState(i18n.language);
  const [selected, setSelected] = useState(0);
  const [opacity, setOpacity] = useState(0.2);
  const handleNavClick = (id) => {
    setSelected(id);
  };
  useEffect(() => {
    console.log("🚀 ~ useEffect ~ selected:", selected)
    if (selected !== 0) {
      document.querySelector("#viz").style.opacity = 0.1;

    } else {
      document.querySelector("#viz").style.opacity = 1;
    }
  }, [selected]);

  const { springOpaciy } = useSpring({
    springPosition: opacity,
    config: { duration: 1000 }, // configure the duration for 2 seconds
  });

  const { springPosition } = useSpring({
    springPosition: position,
    config: { duration: 1000 }, // configure the duration for 2 seconds
  });

  return (
    <><LoadingOverlay className="overlay-loader" visible={isLoading} overlayColor={'black'} overlayOpacity={0.9} loader={<Loader color='white' size="xl" variant="bars" />} />

      <div className={`App ${theme}`}><div className="new-nav">

        <div>
          <button className={selected === 0 ? "active-nav-btn" : ""} onClick={() => handleNavClick(0)}>{t('home')}</button>

        </div>
        <div>
          <button className={selected === 2 ? "active-nav-btn" : ""} onClick={() => handleNavClick(2)}>{t('projects')}</button>

        </div>
        <div>
          <button className={selected === 4 ? "active-nav-btn" : ""} onClick={() => handleNavClick(4)}>{t('contact')}</button>
        </div>

      </div>
        {/* <MP3Player /> */}
        <div className="main">

          <Main projects={projects} exp={exp} skills={skills} selectedItem={selectedItem} setSelectedItem={setSelectedItem} selected={selected}
            setSelected={setSelected} />
        </div>

        <Details selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <Carou selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      </div></>

  );
};

export default App;