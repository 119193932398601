import { IconCaretRight } from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";

const NewHome = ({setSelected}) => {
  const { t } = useTranslation();

  return (
    <div className="home-header">
      <h1>Khamri Achraf</h1>
      <p>{t("title")}</p>
      <div>{t("description")}</div>
      <IconCaretRight onClick={() => setSelected(2)} className="start-icon" size={100} stroke={0.3} />
    </div>
  );
};

export default NewHome;
