import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Chip from "./Chip";
import Particles from "./Particles";
import { Group, Image } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

import {
  IconArrowAutofitContent,
  IconArrowLeft,
  IconBrandGithub,
  IconCaretDown,
  IconCaretUp,
  IconChevronLeft,
  IconChevronRight,
  IconZoomIn,
} from "@tabler/icons-react";
import { IconEye } from "@tabler/icons-react";
import { IconCaretLeft } from "@tabler/icons-react";

const formatDate = (date) => {
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString(undefined, options);
};

const Carou = (props) => {
  const handleClose = () => {
    document.querySelector(".exp-details").classList.remove("up");
    document.querySelector(".exp-car").classList.remove("visible");
    setTimeout(() => {
      document.querySelector("#viz").classList.remove("hide");
      document.querySelector(".player").classList.remove("hide");
      document.querySelector(".main").classList.remove("hide");
      document.querySelector(".new-nav").classList.remove("hide");
      document.querySelector(".exp-details").classList.remove("visible");
    }, 300);

    props.setSelectedItem();
  };

  const handleClickDown = () => {
    document.querySelector(".exp-details").classList.remove("up");
    document.querySelector(".exp-car").classList.remove("visible");
  };

  console.log(props.selectedItem);

  const handleExpandImage = (item) => {
    window.open(item, '_blank').focus();

  }

  return (
    <div className="exp-car">
      {props.selectedItem && (
        <div className="exp-header">
          <IconCaretLeft
            size={45}
            stroke={1}
            onClick={handleClose}
            className="close-icon"
          />
          <IconCaretUp
            size={45}
            stroke={1}
            onClick={handleClickDown}
            className="close-icon"
          />
          <div className="exp-row">
            <p className="car-row-title">Gallery</p>
          </div>
        </div>
      )}
      {props.selectedItem && (
        <div className="exp-content">
          <div className="exp-carou">
            <Carousel
              previousControlIcon={<IconChevronLeft size={45} stroke={1} />}
              nextControlIcon={<IconChevronRight size={45} stroke={1} />}
              withIndicators
              className="car-carousel"
              loop
            >
              {props.selectedItem.gallery?.map((item) => {
                return (
                  <Carousel.Slide>
                    <IconZoomIn
                      size={45}
                      stroke={1}
                      onClick={() => handleExpandImage(item)}
                      className="car-expand-icon"
                    />
                    <Image fit="contain" src={item} />
                  </Carousel.Slide>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carou;
