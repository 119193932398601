import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then(res => res.json())

export function useProjects() {
    const { data, error, isLoading } = useSWR('https://us-central1-portfolio-385821.cloudfunctions.net/getProjects', fetcher)

    return {
        projects: data,
        isLoading,
        isError: error
    }
}